.animated {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

@-webkit-keyframes enterRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes enterRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.enterRight {
  -webkit-animation-name: enterRight;
  animation-name: enterRight;
}

@-webkit-keyframes enterLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes enterLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.enterLeft {
  -webkit-animation-name: enterLeft;
  animation-name: enterLeft;
}

@-webkit-keyframes exitRight {
  from {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
  }
}

@keyframes exitRight {
  from {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
  }
}

.exitRight {
  -webkit-animation-name: exitRight;
  animation-name: exitRight;
}

@-webkit-keyframes exitLeft {
  from {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
  }
}

@keyframes exitLeft {
  from {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
  }
}

.exitLeft {
  -webkit-animation-name: exitLeft;
  animation-name: exitLeft;
}
