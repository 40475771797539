@import "fonts/fonts.css";

* {
  box-sizing: border-box;
}

body,
button,
input,
textarea {
  margin: 0;
  font-family: "Roboto", BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

a {
  color: #23a085;
  text-decoration: underline;
  text-decoration-color: #23a085;
}

a:hover,
a:focus {
  color: #1e8972;
}
